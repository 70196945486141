<template>
  <div>
    <MainAppBar>
      <template v-slot:title> Activity Log </template>
    </MainAppBar>

    <div
      class="mt-5 px-12 mb-10"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <simple-table
        :loading="loading"
        :headers="tableHeaders"
        :collection="activities"
        :sort="sort"
        @headerClicked="sortActivity"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
} from '@mdi/js'

import MainAppBar from '@/layouts/shared/MainAppBar'
import ControlsMixin from '@/utils/mixins/Controls'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'ActivityPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    SimpleTable,
    MainAppBar,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,

      sort: '-created_at',

      icons: {
        sort: mdiUnfoldMoreHorizontal,
        settings: mdiDotsVertical,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
      },
    }
  },

  created() {
    this.clearActivities()
    this.fetchActivities(1)
  },

  watch: {
    sort: function (newSort, oldSort) {
      this.clearActivities()
      this.fetchActivities()
    },
  },

  computed: {
    ...mapState({
      activities: (state) => state.activity.list,
      listMeta: (state) => state.activity.listMeta,
    }),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    tableHeaders() {
      return [
        {
          property: 'created_at',
          name: 'Date & Time',
        },
        { property: 'subject_type', name: 'Type', sortable: false },
        { property: 'email', name: 'User Email' },
        { property: 'description', sortable: false },
      ]
    },
  },

  methods: {
    ...mapActions({
      getActivities: 'activity/getActivities',
    }),

    ...mapMutations({
      clearActivities: 'activity/clearActivityList',
    }),

    sortActivity(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    pageChanged(page) {
      this.fetchActivities(page)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchActivities(this.listMeta.current_page + 1)
      }
    },

    async fetchActivities(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
      }

      this.loading = true
      await this.getActivities(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    exportActivity() {
      //
    },
  },
}
</script>
